const loader = {
    show: el => (el || document.getElementById('loader')).classList.remove('d-none'),
    hide: el => (el || document.getElementById('loader')).classList.add('d-none'),
}
const removeDOMElement = element => element.parentNode.removeChild(element);
const removeDOMElements = elements => elements.forEach(removeDOMElement);
const proceedJsonResponse = (jsonResponse, response, form) => {
    if (response.status === 201) {
        loader.hide();
        form.parentNode.parentNode.querySelector('.alert-success').classList.remove('d-none');
        return;
    }
  /***********************
   *    @todo JSON form  *
   ***********************/
};
const proceedHtmlResponse = (htmlResponse, response, form) => {
    if (response.status === 201) {
        loader.hide();
        form.parentNode.parentNode.querySelector('.alert-success').classList.remove('d-none');
        return;
    }
    const parent = form.parentNode;
    removeDOMElement(form);
    parent.innerHTML = htmlResponse;
    loader.hide();
    parent.classList.remove('d-none');
    bindForm(parent.querySelector('form'));
};
const saveForm = async form => {
    const resolution = `resolution=${window.innerWidth}x${window.innerHeight}`;
    let url = form.getAttribute('action');
    url = url + (url.match(/\?/) ? '&' : '?') + resolution;
    removeDOMElements(Array.from(form.querySelectorAll('.error')));
    form.parentNode.classList.add('d-none');
    loader.show();
    const response = await fetch(url, {
        method: 'POST',
        body: new FormData(form)
    });
  const contentType = response.headers.get("content-type");
if (contentType && contentType.indexOf("application/json") !== -1) {
    proceedJsonResponse(await response.json(), response, form);
} else {
    proceedHtmlResponse(await response.text(), response, form);
}
}
const bindForm = form => {
    console.log(form);
    form.addEventListener('submit', event => {
        event.preventDefault();
        form.checkValidity();
        form.classList.add('was-validated')
        saveForm(form);
        event.stopPropagation();
    }, false)
}
const toggleBillPos = (billTypeEl, billPosEl) => {
    if(billTypeEl.value === 'Faktura') {
        billPosEl.parentNode.classList.add('d-none')
        billPosEl.value = ""
        billPosEl.required = false
    } else {
        billPosEl.parentNode.classList.remove('d-none')
        billPosEl.required = true
    }
}

document.querySelectorAll('form[name=personal_data], form[name=contact]').forEach(bindForm);
if (document.querySelector('#personal_data_billType')) {
    document.querySelector('#personal_data_billType').addEventListener('change', event => toggleBillPos(event.target, document.querySelector('#personal_data_billPos')));
}