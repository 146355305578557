import { createApp } from 'vue/dist/vue.esm-bundler.js'
import { ajaxFormSubmit } from './form';

let app = createApp({
    delimiters: ['${', '}'],
    data() {
        return {
            step: 1,
            counties: [],
            cities: [],
            facilitySearchTimeout: null,
            facilitySearchXhr: null,
            facilities: null,
            facility: null,
            createFacility: false,
            contactToTechers: [],
            newFacility: {},
            user: {},
            facilityCheck: false,
            facilityCheckResponse: null,
        }
    },
    computed: {
        canSearch() {
            return this.newFacility?.city != '' && (
                this.newFacility.name?.length >= 3 ||
                this.newFacility.street?.length >= 3 ||
                this.newFacility.streetNr?.length >= 1 ||
                this.newFacility.zipCode?.length >= 3
            );
        }
    },
    methods: {
        stopSearching() {
            this.facilitySearchXhr && this.facilitySearchXhr.abort();
            this.facilitySearchTimeout && clearTimeout(this.facilitySearchTimeout);
        },
        searchFacilities() {
            this.stopSearching();
            this.facilitySearchTimeout = setTimeout(() => {
                if (!this.canSearch) {
                    this.facilities = null;
                    this.facility = null;
                    return;
                }
                let params = new URLSearchParams(this.newFacility);
                this.facilitySearchXhr = $.getJSON(`/options/facility?${params.toString()}`, data => {
                    this.facility = null;
                    this.facilities = data;
                });
            }, 500);
        },
        async confirmFacilitySelection() {
            this.facilityCheck = true;
            this.facilityCheckResponse = await $.getJSON(`/check-facility/${this.facility.id}`);
            if (this.facilityCheckResponse.allowed) {
                this.facilityCheck = false;
                this.step = 2;
            }
        },
        selectExistingFacility(facility) {
            if (this.facilityCheck) return;
            this.facility = facility;
            this.confirmFacilitySelection();
        }
    },
    mounted() {
        $(this.$el).parent().removeClass('d-none');
        let that = this;
        $('form', this.$el).on('submit', function (event) {
            event.preventDefault();
            $(this).addClass('was-validated');
            if (!this.checkValidity()) {
                event.stopImmediatePropagation();
                $(this).trigger('submit-invalid');
            } else if (that.step == 1) {
                that.step = 2;
                that.facility = null;
            } else if (that.step == 2) {
                ajaxFormSubmit(event);
            }
        }).on('submit-error', function (e, xhr) {
            if (xhr.responseJSON?.errors?.facility) {
                that.step = 1;
                that.confirmFacilitySelection();
            }
        }).on('submit-success', function (e, data) {
            that.step = 3;
        });
    },
    watch: {
        step() {
            this.stopSearching();
            $('form', this.$el).removeClass('was-validated');
        },
        async 'newFacility.province'(val) {
            this.newFacility.county = undefined;
            if (val) {
                this.counties = await $.getJSON(`/options/county?parent=${val}`);
            } else {
                this.counties = [];
            }
        },
        async 'newFacility.county'(val) {
            this.newFacility.city = undefined;
            if (val) {
                this.cities = await $.getJSON(`/options/city?parent=${val}`);
            } else {
                this.cities = [];
            }
        },
        'newFacility.city'() { this.searchFacilities(); },
        'newFacility.name'() { this.searchFacilities(); },
        'newFacility.street'() { this.searchFacilities(); },
        'newFacility.streetNr'() { this.searchFacilities(); },
        'newFacility.zipCode'() { this.searchFacilities(); },
    },
    directives: {
        init: {
            mounted: el => $(el).initJs()
        }
    }
}).mount('#registrationApp');

$(document).ready(function () {
    $(window).keydown(function (event) {
        if (event.keyCode === 13) {
            event.preventDefault();
            return false;
        }
    });
});