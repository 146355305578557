import $2 from "jquery";
window.$2 = $2;
import 'bootstrap';
import 'popper.js';
import 'bootstrap-datepicker';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css';
import 'simplebar/dist/simplebar.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick';
import 'simplelightbox/dist/simple-lightbox.jquery';
import 'simplelightbox/dist/simple-lightbox.min.css';

"use strict";

let lang = 'pl';
let heights = { '1400': '150' };
let gallery = null;

$(function() {
    lang = $('html').attr('lang');

    gallery = $('.gallery a').simpleLightbox({
        uniqueImages: false,
    });

    $('#load-gallery-items').on('click', function () {
        let itemsToShow = [];

        for (let i = 0; i < 20; i++) {
            itemsToShow.push($($('.gallery a.d-none')[i]));
        }

        itemsToShow.forEach(function (item) {
            item.removeClass('d-none');
        });

        if ($('.gallery a.d-none').length === 0) {
            $(this).remove();
        }
    });

    //registration end timer
    let countDownDate = new Date($('#registration-end-timer').attr('data-countdown')).getTime();
    let x = setInterval(function () {
        const now = new Date().getTime();
        let distance = countDownDate - now;

        let days = Math.floor(distance / (1000 * 60 * 60 * 24));
        let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((distance % (1000 * 60)) / 1000);

        $('#registration-end-timer').html(days + '&nbsp;<span>DNI</span> ' + hours + '&nbsp;<span>GODZ.</span> '
            + minutes + '&nbsp;<span>MIN.</span> ' + seconds + '&nbsp;<span>SEC.</span>');

        if (distance < 0) {
            clearInterval(x);
            $('#registration-end-timer').html('0&nbsp;<span>DNI</span> 0&nbsp;<span>GODZ.</span> 0&nbsp;<span>MIN.</span> 0&nbsp;<span>SEC.</span>');
        }
    }, 1000);


    const url = '/free-slots';
    $.ajax({
        url: url,
        type: 'GET',
        complete: function (response) {
            const data = response.responseJSON;
            if (data.freeSlots <= 0) {
                if (lang === "ua") {
                    $('.free-slots').find('div.slots').text('0 mistsʹ!');
                }
                else {
                    $('.free-slots').find('div.slots').text('Brak miejsc!');
                }
            } else {
                $('.free-slots').find('span.free').text(data.freeSlots);
            }

            $('.free-slots').find('.slots').removeClass('loading-skeleton');
        }
    });

    if ($('#iframe-page').length > 0) {
        heights = JSON.parse($('#iframe-page').attr('data-size')) || heights;

        $('#iframe-page').on('load', function () {
            Object.keys(heights).forEach(function (width) {
                if ($(window).width() >= parseInt(width, 10)) {
                    $('#iframe-page').css('height', `${heights[width]}px`);
                    return true;
                }
            });
        });
    }

    $(window).resize(function() {
        if ($('#iframe-page').length > 0) {
            Object.keys(heights).forEach(function (width) {
                if ($(window).width() >= parseInt(width, 10)) {
                    $('#iframe-page').css('height', `${heights[width]}px`);
                    return true;
                }
            });
        }
    });
    $(window).resize();

    $('.slider').each(function () {
        const slidesToShow = $(this).data('slides-to-show');
        const arrows = $(this).data('arrows');
        const dots = $(this).data('dots');

        $(this).slick({
            slidesToShow: slidesToShow,
            arrows: arrows,
            dots: dots,
            draggable: $(this).closest('#workpage').length === 0
        });
    });

    $('[data-check-words="true"]').on('keypress keyup keydown', function (e) {
        let words = $(this).val().split(/\s+/);
        let count = words.length;
        const max = parseInt($(this).attr('data-max-words'));

        if (count >= max && e.keyCode !== 8) {
            const wordsDiff = count - max;

            if (wordsDiff > 0) {
                $(this).val(words.slice(0, max).join(' '));
            }

            e.preventDefault();
        }

        words = $(this).val().split(/\s+/);
        count = $(this).val().length > 0 ? words.length : 0;

        $(this).closest('.form-group').find('.details .current-value').text(count);
    });

    $('input[name="email"], input[name="email2"]').on('keypress keyup keydown', function (e) {
        const ctrlDown = e.ctrlKey || e.metaKey

        if (ctrlDown && e.keyCode === 86) {
            e.preventDefault();
        }
    });

    $('div[data-news="true"]').each(function () {
        const self = this;
        const url = '/category/news';
        let data = {
            category: $(self).data('categoryid'),
        };

        if ($('#workpage').length === 0) {
            data.lang = lang;
        }

        $.ajax({
            url: url,
            type: 'GET',
            data: data,
            complete: function (response) {
                const news = response.responseJSON;

                $(self).find('.news-item').each(function (key) {
                    if (news[key]) {
                        if ($($(this).find('a.image-link')).find('img').length > 0) {
                            $($(this).find('a.image-link')).find('img').remove();
                        }
                        $($(this).find('a.image-link')).append(`<img src="${news[key].image}" class="card-img-top" alt="${news[key].title}">`);
                        $($(this).find('a')).attr('href', news[key].href);
                        $($(this).find('.card-title')).text(news[key].title);
                        $($(this).find('.card-text')).text(`${news[key].lead.substring(0, 250)}...`);
                        $(this).removeClass('d-none');

                        $(this).removeClass('loading-skeleton');
                    } else {
                        $(this).remove();
                    }
                });

                $(self).closest('section').find('.article-link').attr('href', news.categoryLink);
            }
        });
    });

    $('.contact-section textarea[name="tresc"]').on('keypress keyup keydown', function (e) {
        const words = this.value.split(/\s+/);
        const count = words.length;
        $(this).closest('.form-group').find('.details .current-value').text(count);

        if (count >= 255) {
            const wordsDiff = count - 255;

            if (wordsDiff > 0) {
                $(this).val(words.slice(0, 255).join(' '));
            }

            e.preventDefault();
        }
    });

    $('.contact-section input[name="telefon"]').on('keypress', function (e) {
        const count = $(this).val().length;

        if (count > 8 || (e.keyCode < 48 || e.keyCode > 57)) {
            e.preventDefault();
        }
    });

    $('.contact-section .btn-submit').on('click', function (e) {
        e.preventDefault();

        const form = $(this).closest('form');
        const formData = form.serializeArray();
        const agree = formData.find(function (item) { return item.name === 'agree' });

        if (agree) {
            agree.value = $('input[name="agree"]').is(':checked')
        } else {
            formData.push({
                name: 'agree',
                value: $('input[name="agree"]').is(':checked')
            });
        }

        if (checkFormValidation(formData)) {
            formData.push(
                {
                    name: 'subject',
                    value: `Kontakt ze strony: ${window.location.href}`
                },
                {
                    name: 'sendTo',
                    value: form.attr('data-email')
                });

            $.ajax({
                url: form.attr('action'),
                type: 'POST',
                data: formData,
                complete: function (response) {
                    if (response.responseJSON.status === 'success') {
                        $(form).prepend('<div class="message-was-sent">Dziękujemy, wiadomość została wysłana.</div>');
                    } else {
                        $(form).prepend('<div class="message-was-not-sent">Przepraszamy. wiadomość nie została wysłana</div>');
                    }

                    formData.forEach(function (field) {
                        if ($(`form [name="${field.name}"]`)) {
                            $(`form [name="${field.name}"]`).removeClass('is-valid');
                            $(`form [name="${field.name}"]`).removeClass('is-invalid');

                            if (response.responseJSON.status === 'success') {
                                if ($(`form [name="${field.name}"]`).attr('type') === 'checkbox') {
                                    $(`form [name="${field.name}"]`).prop('checked', false);
                                } else {
                                    $(`form [name="${field.name}"]`).val('');
                                }
                            }
                        }
                    });
                }
            });
        }
    });
});

function checkFormValidation(fields) {
    let isValid = true;
    const regexErrors = {
        pl: {
            email: 'Nieprawidłowy adres e-mail',
            email2: 'Nieprawidłowy adres e-mail',
            telefon: 'Numer telefonu powinien składać się z {range} cyfr',
            imie: 'Imie powinno się składać z samych liter',
            nazwisko: 'Nazwisko powinno się składać z samych liter',
            numberRange: 'Wartość powinna mieścić się w zakresie '
        },
        en: {
            email: 'Incorrect e-mail address',
            email2: 'Incorrect e-mail address',
            telefon: 'The telephone number should consist of {range} digits',
            imie: 'First name should consist of letters',
            nazwisko: 'Surname should consist of letters',
            numberRange: 'The value should be in the range '
        },
        de: {
            email: 'Falsche E-Mail Adresse',
            email2: 'Falsche E-Mail Adresse',
            telefon: 'Die Telefonnummer sollte aus {range} Ziffern bestehen',
            imie: 'Der Vorname sollte aus Buchstaben bestehen',
            nazwisko: 'Der Nachname sollte aus Buchstaben bestehen',
            numberRange: 'Der Wert sollte im Bereich liegen '
        }
    }

    const requiredErrors = {
        pl: 'To pole jest wymagane',
        en: 'This field is required',
        de: 'Dieses Feld ist erforderlich'
    }

    fields.forEach(function (field) {
        const pattern = $('form').find(`[name="${field.name}"]`).attr('pattern');
        let validationMessage = '';

        if (pattern) {
            const regex = new RegExp(pattern);

            if (regex.test(field.value)) {
                $(`form [name="${field.name}"]`).removeClass('is-invalid');
                $(`form [name="${field.name}"]`).addClass('is-valid');
                validationMessage = '';
            } else {
                $(`form [name="${field.name}"]`).removeClass('is-valid');
                $(`form [name="${field.name}"]`).addClass('is-invalid');
                isValid = false;
                if (field.name === 'telefon') {
                    const range = $(`form [name="${field.name}"]`).attr('data-range') || '9';
                    validationMessage = regexErrors[lang][field.name].replace('{range}', range);
                } else {
                    validationMessage = regexErrors[lang][field.name];
                }
            }
        } else {
            if (!field.value && $(`form [name="${field.name}"]`).get(0).hasAttribute('required')) {
                $(`form [name="${field.name}"]`).removeClass('is-valid');
                $(`form [name="${field.name}"]`).addClass('is-invalid');
                isValid = false;
                validationMessage= requiredErrors[lang];
            } else if (field.value && $(`form [name="${field.name}"]`).get(0).hasAttribute('min')
                && (parseInt($(`form [name="${field.name}"]`).attr('min'), 10) > parseInt(field.value, 10)
                    || parseInt($(`form [name="${field.name}"]`).attr('max'), 10) < parseInt(field.value, 10))
                && $(`form [name="${field.name}"]`).get(0).hasAttribute('max')) {
                $(`form [name="${field.name}"]`).removeClass('is-valid');
                $(`form [name="${field.name}"]`).addClass('is-invalid');
                isValid = false;
                validationMessage= `${regexErrors[lang]['numberRange']}${$(`form [name="${field.name}"]`).attr('min')} - ${$(`form [name="${field.name}"]`).attr('max')}`;
            } else {
                $(`form [name="${field.name}"]`).removeClass('is-invalid');
                $(`form [name="${field.name}"]`).addClass('is-valid');
                validationMessage = '';
            }
        }

        $(`form [name="${field.name}"]`).closest('.form-group').find('.validation-error').text(validationMessage);
    });

    return isValid;
}
