// window.$ = $;

import 'slick-carousel';
import 'slick-carousel/slick/slick.css';

window.alert = function(message, type = '') {
    var $toast = $(`
        <div class="toast ${type ? `toast-${type}` : ''}" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="toast-body">
                <button type="button" class="ml-2 mb-1 mt-n1 close" data-dismiss="toast" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                ${message}
            </div>
        </div>
    `);
    $toast.appendTo('.toasts').toast({
        delay: 10000
    }).toast('show');
    $toast.on('click', () => $toast.toast('hide'));
    setTimeout(() => $toast.addClass('active'));
};

// usuwa problem z pojawianiem się outline podczas klikania w przycisk/link
$(document).on('keydown keyup', function(e) {
    if (e.which == 9) {
        $('html').removeClass('mouse-focus').addClass('tab-focus');
    }
})
$(document).on('mousedown mouseup', function () {
    $('html').addClass('mouse-focus').removeClass('tab-focus');
});

$(window).on('activate.bs.scrollspy', function (e, target) {
    history.replaceState({}, '', target.relatedTarget);
});

$.fn.initJs = function () {
    $(window).trigger('init', this);
};

window.addEventListener('DOMContentLoaded', () => $('body').initJs());

// przenosi pojedyncze znaki z końca linijki na początek następnej
export function addNbsps(el) {
    $(el).find(":not(iframe):not(style):not(script)").add(el).contents().filter((i, n) => n.nodeType == 3).each(function () {
        var startWhitespace = /^\s/.test(this.nodeValue) ? ' ' : '';
        var endWhitespace = /\s$/.test(this.nodeValue) ? ' ' : '';
        this.nodeValue = startWhitespace + (' ' + this.nodeValue + ' ').replace(/(\s\w)\s+/g, "$1\xA0").trim() + endWhitespace;
    });
};

$(window).on('init', function(e, el) {
    window.initEl = el;
    setTimeout(() => addNbsps(window.initEl));

    $('[data-toggle="ajax-modal"]', el).on('click', function() {
        $('.ajax-modal .modal-dialog').removeClass('modal-sm modal-lg modal-xl');
        if ($(this).data('modal-size')) {
            $('.ajax-modal .modal-dialog').addClass(`modal-${$(this).data('modal-size')}`);
        }
        $('.ajax-modal').modal('show');
        $('.ajax-modal .modal-body').html('').load($(this).data('href'), function() {
            $(this).initJs();
        });
    });

    $('.modal-form', el).on('submit-success', function(e) {
        e.preventDefault();
        //alert('Zapisano drużynę.');
        $('.ajax-modal').modal('hide');
        setTimeout(() => window.location.reload(), 100);
    });

    $('.delete-team-link', el).on('click', async function() {
        if ($(this).hasClass('disabled')) {
            return;
        }
        if (!confirm('Czy na pewno chcesz usunąć drużynę dla tej gry?')) {
            return;
        }
        $(this).addClass('disabled');
        await $.get($(this).data('href'));
        $('.ajax-modal').modal('hide');
        setTimeout(() => window.location.reload(), 100);
    });

    $('[data-toggle="tooltip"]', el).tooltip();
});

$('.jumbotron-slider').slick({
    arrows: false,
    dots: true,
    autoplay: true,
});

$('.carousel-slider').slick({
    arrows: true,
    dots: true,
    autoplay: true,
});

$('.logos').each(function () {
    $(this).slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        mobileFirst: true,
        adaptiveHeight: true,
        autoplay: true,
        centerMode: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: $(this).find('img').length > 3 ? 3 : $(this).find('img').length
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: $(this).find('img').length > 7 ? 7 : $(this).find('img').length
                }
            }
        ]
    });
});
