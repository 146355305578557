require('bs-custom-file-input').init();

$(window).on('init', (e, el) => {
    $('.needs-validation', el).on('submit', function (event) {
        $(this).addClass('was-validated');
        if (!this.checkValidity()) {
            event.preventDefault();
            event.stopImmediatePropagation();
            $(this).trigger('submit-invalid');
        }
    });
});

export function getRecaptcha()
{
    let url = new URL(document.querySelector('script[src^="https://www.google.com/recaptcha/api.js"]').src);
    let params = new URLSearchParams(url.search);
    return grecaptcha.execute(params.get('render'));
};

$.fn.ajaxInvalidFeedback = function (options) {
    return this.each(function () {
        let object = $(this).data('ajaxInvalidFeedback');
        if (typeof options == 'object') {
            $(this).addClass('has-invalid-feedback-ajax').after(`
                <div class = 'invalid-feedback invalid-feedback-ajax d-block'> ${options.error} </div>
            `);
            let destroyHandler = () => $(this).ajaxInvalidFeedback('destroy');
            $(this).on('change input', destroyHandler);
            $(this.form).on('submit', destroyHandler);
            $(this).data('ajaxInvalidFeedback', {destroyHandler});
        } else if (options === 'destroy') {
            $(this).off('change input', object.destroyHandler).removeClass('has-invalid-feedback-ajax').siblings('.invalid-feedback-ajax').remove();
            $(this.form).off('submit', object.destroyHandler);
            $(this).data('ajaxInvalidFeedback', null);
        }
    });
};

$.fn.toggleValid = function (valid) {
    this[0].setCustomValidity(valid ? "" : 'invalid');
}

export function processFormErrors(errors, form)
{
    for (let fieldName in errors) {
        if (fieldName == '') {
            for (let error of errors[fieldName]) {
                alert(error);
            }
        } else {
            let error = errors[fieldName].join('<br>');
            $(form.elements[fieldName]).ajaxInvalidFeedback({error: error});
        }
    }
}

export async function ajaxFormSubmit(event)
{
    event.preventDefault();
    let form = event.target;
    $(form.elements).filter('[type=submit]').prop('disabled', true);
    let formData = new FormData(form);
    if ($(form).data('recaptcha')) {
        formData.set('g-recaptcha-response', await getRecaptcha());
    }
    try {
        var data = await $.ajax({
            type: 'post',
            url: form.action,
            data: formData,
            processData: false,
            contentType: false,
        });
    } catch (xhr) {
        let errorEvent = $.Event('submit-error');
        $(form).trigger(errorEvent, xhr);
        if (!errorEvent.isDefaultPrevented()) {
            if (xhr.responseJSON && xhr.responseJSON.errors) {
                processFormErrors(xhr.responseJSON.errors, form);
            } else {
                alert("Wystąpił nieznany błąd, spróbuj ponownie później.");
            }
        }
        return;
    } finally {
        $(form.elements).filter('[type=submit]').prop('disabled', false);
    }
    let successEvent = $.Event('submit-success');
    $(form).trigger(successEvent, data);
    if (!successEvent.isDefaultPrevented()) {
        form.reset();
        $(form).removeClass('was-validated');
        if (data.redirect) {
            window.location = data.redirect;
        }
    }
}

$(window).on('init', (e, el) => {
    $('.ajax-form', el).on('submit', ajaxFormSubmit);

    $('[data-same-as]', el).each(function () {
        var $input = $(this);
        var $sameAs = $($(this).data('same-as'));
        $(this).add($sameAs).on('input', function () {
            $input.toggleValid($input.val() == $sameAs.val());
        });
        $input.toggleValid($input.val() == $sameAs.val());
    });
});

$('.custom-file-input').on('change', function (event) {
    var inputFile = event.currentTarget;
    $(inputFile).parent()
        .find('.custom-file-label')
        .html(inputFile.files[0].name);
});

$(document).ready(function () {
    $(window).keydown(function (event) {
        if (event.keyCode === 13) {
            event.preventDefault();
            return false;
        }
    });
});