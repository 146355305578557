import 'bootstrap';

// keep these scss files as separate modules for faster builds (import here instead in the global scss)
import './styles/bootstrap/bootstrap.scss';
import './styles/app.scss';

import './js/app';
import './js/form';
import './js/workflow-form';
import './js/application';
import './js/registration';
import './js/main';
import './js/menu';
import './js/swiper';

import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all';

global.jQuery = jQuery;